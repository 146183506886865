<template>
  <el-container class="h-100p">
    <el-aside width="250px" v-if="userInfo.tenant_id === '000000'">
      <basic-container isCard class="h-100p ov-a">
        <el-input
          class="mar-b-15"
          size="small"
          placeholder="输入关键字进行过滤"
          clearable
          v-model="treeFilterText"
        ></el-input>
        <el-tree
          ref="tree"
          node-key="id"
          :data="catalogData"
          :props="treeProps"
          :expand-on-click-node="false"
          @node-click="treeNodeClick"
          :filter-node-method="filterNode"
        >
        </el-tree>
      </basic-container>
    </el-aside>
    <el-main class="pad-l-15">
      <basic-container>
        <el-card class="mar-b-15" v-if="userInfo.tenant_id === '000000'">
          <el-form ref="searchForm" :model="searchForm" size="small" label-position="left" label-width="100px">
            <el-row>
              <el-col :span="searchForm.subjectType == 1 ? 4 : 6">
                <el-form-item label="账单对象类型" prop="subjectType">
                  <el-select class="dis-b" v-model="searchForm.subjectType" @change="subjectTypeChange">
                    <el-option
                      v-for="item of dictData.receiptSubjectType"
                      :key="item.id"
                      :label="item.dictValue"
                      :value="item.dictKey"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="searchForm.subjectType == 1" :span="6">
                <el-form-item label="所属地块" prop="subPartId" label-width="80px">
                  <model-search-select
                    class="dis-b"
                    v-model="searchForm.subPartId"
                    model="wp_part"
                    @change="searchForm.buildingId = ''"
                  ></model-search-select>
                </el-form-item>
              </el-col>
              <el-col v-if="searchForm.subjectType == 1" :span="6">
                <el-form-item label="所属楼宇" prop="buildingId" label-width="80px">
                  <model-search-select
                    class="dis-b"
                    v-model="searchForm.buildingId"
                    model="wp_building"
                    :notInitLoadData="true"
                    :params="{ sub_part_id: searchForm.subPartId }"
                  ></model-search-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账单对象名称" prop="subject">
                  <model-search-select
                    class="dis-b"
                    v-if="searchForm.subjectType == 1"
                    v-model="searchForm.subject"
                    model="v_wp_company_building_code_stat"
                    :params="{}"
                  >
                  </model-search-select>
                  <el-input clearable v-else v-model="searchForm.subject" class="dis-b"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="searchForm.subjectType == 1 ? 6 : 8">
                <el-form-item label="账单编号" prop="receiptNo">
                  <el-input class="dis-b" clearable v-model="searchForm.receiptNo"></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="10">
                <el-form-item label="缴费时间" prop="time_startTime_endTime" label-width="100px">
                  <el-date-picker
                    class="w-100p"
                    v-model="searchForm.time_startTime_endTime"
                    :unlink-panels="true"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col v-if="showReceiptTime" :span="10">
                <el-form-item label="账单周期" prop="time_periodStart_periodEnd" label-width="80px">
                  <el-date-picker
                    class="w-100p"
                    v-model="searchForm.time_periodStart_periodEnd"
                    :unlink-panels="true"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="searchForm.subjectType == 1 ? 24 : 6"> -->
              <el-col :span="4">
                <el-form-item label-width="0px">
                  <div>
                    <el-button icon="el-icon-delete" @click="clearSearchForm">清 空</el-button>
                    <el-button icon="el-icon-search" type="primary" @click="searchTable">搜 索</el-button>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
        <ReceiptTable :queryParams="tableQueryParams"></ReceiptTable>
      </basic-container>
    </el-main>
  </el-container>
</template>
<script>
import { getMetaCommonListTree } from "@/api/busiMode/metaCommon"

import ReceiptTable from "./receipt-table.vue"
import { getDictionary } from "@/api/system/dictbiz.js"
import { mapState } from "vuex"
import dayjs from "dayjs"

export default {
  components: { ReceiptTable },
  data() {
    let startTime = `${dayjs().year()}-01-01`,
      endTime = `${dayjs().format("YYYY-MM-DD")}`
    return {
      searchForm: {
        subjectType: "1",
        subject: "",
        time_startTime_endTime: [],
        time_periodStart_periodEnd: [`${dayjs().year()}-01-01`, `${dayjs().year()}-12-31`],
        subPartId: "",
        buildingId: "",
        receiptNo: "",
      },
      catalogModel: "wisdom_park.wp_expense_account",
      model: "wisdom_park.wp_receipt",
      catalogData: [],
      treeProps: {
        label: "expense_account_name",
      },
      treeFilterText: "",
      tableQueryParams: {
        subjectType: 1,
        periodStart: dayjs(`${dayjs().year()}-01-01`).format("YYYY-MM-DD HH:mm:00"),
        periodEnd: dayjs(`${dayjs().year()}-12-31`).format("YYYY-MM-DD HH:mm:00"),
      },
      dictData: {
        receiptSubjectType: [],
      },
    }
  },
  watch: {
    treeFilterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  computed: {
    // 获得用户信息来区分角色
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    showReceiptTime() {
      let codes = ["1009", "1008"]
      if (codes.includes(this.tableQueryParams.expenseAccountCode)) {
        this.searchForm.time_periodStart_periodEnd = []
        delete this.tableQueryParams["periodStart"]
        delete this.tableQueryParams["periodEnd"]
        return false
      }
      return true
    },
  },
  mounted() {
    if (this.userInfo.tenant_id === "000000") {
      this.getMetaCommonListTree()
    }
    this.getDictionary()
  },
  methods: {
    clearSearchForm() {
      this.$refs.searchForm.resetFields()
    },
    subjectTypeChange() {
      this.searchForm.subject = ""
      this.searchForm.subPartId = ""
      this.searchForm.buildingId = ""
      this.searchForm.subjectId = ""
    },
    getDictionary() {
      ;[{ prop: "receiptSubjectType", model: "ReceiptSubjectType", isAll: true }].forEach((item) => {
        getDictionary({
          code: item.model,
        }).then((res) => {
          let data = res.data.data || []
          if (item.isAll) {
            data = [{ dictValue: "全部", dictKey: "" }, ...data]
          }
          this.dictData[item.prop] = data
          if (item.call) {
            this[item.cal](data)
          }
        })
      })
    },
    getMetaCommonListTree() {
      getMetaCommonListTree(this.catalogModel, {
        labelField: "expense_account_name",
        valueField: "id",
        releaseField: "parent_id",
        isLazy: false,
      }).then((res) => {
        this.catalogData = [
          {
            expense_account_name: "全部",
            id: "all",
          },
        ].concat(res.data.data)
        this.$nextTick(() => {
          this.$refs.tree.setCurrentNode(this.catalogData[0])
        })
      })
    },
    treeNodeClick(ev) {
      if (ev) {
        let tableQueryParams = {
          ...this.getSearchForm(),
        }
        if (ev.id !== "all") {
          tableQueryParams = {
            ...tableQueryParams,
            expenseAccountCode: ev["expense_account_code"],
          }
        }
        this.tableQueryParams = tableQueryParams
      }
    },
    getSearchForm() {
      let searchForm = {}
      Object.keys(this.searchForm).forEach((key) => {
        let names = key.split("_")
        if (names[0] !== "time") {
          if (this.tableQueryParams[key] || this.searchForm[key]) {
            searchForm[key] = this.searchForm[key]
          }
        } else {
          let sName = names[1],
            eName = names[2]
          if (this.searchForm[key] && this.searchForm[key].length === 2) {
            if (this.searchForm[key][0] && this.searchForm[key][1]) {
              searchForm[sName] = `${this.searchForm[key][0]} 00:00:00`
              searchForm[eName] = `${this.searchForm[key][1]} 23:59:59`
            }
          }
        }
      })
      return searchForm
    },
    searchTable() {
      Object.keys(this.searchForm).forEach((key) => {
        let names = key.split("_")
        if (names[0] === "time") {
          let sName = names[1],
            eName = names[2]
          delete this.tableQueryParams[sName]
          delete this.tableQueryParams[eName]
        }
      })
      let tableQueryParams = {
        ...this.tableQueryParams,
        ...this.getSearchForm(),
      }
      if (tableQueryParams.subjectType == 1 && tableQueryParams.subject) {
        if (tableQueryParams.subject) {
          tableQueryParams.subjectId = tableQueryParams.subject
        }
        delete tableQueryParams.subject
      } else {
        delete tableQueryParams.subjectId
      }
      this.tableQueryParams = tableQueryParams
    },
    filterNode(value, data) {
      if (!value) return true
      return data.expense_account_name.indexOf(value) !== -1
    },
  },
}
</script>
