import { render, staticRenderFns } from "./SystemBalanceReceipt.vue?vue&type=template&id=0b73bd62&scoped=true"
import script from "./SystemBalanceReceipt.vue?vue&type=script&lang=js"
export * from "./SystemBalanceReceipt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b73bd62",
  null
  
)

export default component.exports