<template>
  <div>
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="option"
      :table-loading="tableLoading"
      :before-open="beforeOpen"
      :before-close="beforeClose"
      :search.sync="search"
      v-model="form"
      @search-change="searchChange"
      @on-load="onLoad()"
      @refresh-change="onLoad()"
      @row-save="saveTableForm"
      @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
      @row-del="rowDel"
      @selection-change="tableSelection"
      @sort-change="sortChange"
    >
      <template slot-scope="{ size }" slot="menuLeft">
        <el-button icon="el-icon-document-checked" :size="size || 'small'" @click="importTamplateDownload()"
          >导入模板下载</el-button
        >
        <el-button icon="el-icon-upload2" :size="size || 'small'" @click="uploadMethod('csh')">资产初始化</el-button>
        <el-button icon="el-icon-upload2" :size="size || 'small'" @click="uploadMethod('plgx')">资产批量更新</el-button>

        <el-button icon="el-icon-download" :size="size || 'small'" :loading="downLoading" @click="exportTable"
          >导 出</el-button
        >
        <el-button icon="el-icon-printer" :size="size || 'small'" @click="printer">打印标签</el-button>
      </template>
      <template slot="menu" slot-scope="{ size, row }">
        <el-button type="text" :size="size || 'small'" icon="el-icon-collection-tag" @click="showAssetTag(row)"
          >查看标签</el-button
        >
      </template>
      <!-- <template slot-scope="{ row }" slot="systemAssetCode">
        {{ row["system_asset_code"] || "-" }}
      </template> -->
      <template slot-scope="{ row }" slot="asset_goods">
        {{ row["asset_goods"] || "-" }}
      </template>
      <!-- <template slot-scope="{disabled,size}" slot="system_asset_codeForm">
      <el-input :disabled="disabled" :size="size || 'small'" placeholder="请输入内容" v-model="form.system_asset_code">
        <template slot="prepend">{{assetCode}}</template>
      </el-input>
    </template>
    <template slot-scope="{disabled,size}" slot="finance_asset_codeForm">
      <el-input :disabled="disabled" :size="size || 'small'" placeholder="请输入内容" v-model="form.finance_asset_code">
        <template slot="prepend">{{assetCode}}</template>
      </el-input>
    </template> -->
      <template slot-scope="{ disabled, size }" slot="asset_belong_org_idSearch">
        <model-cascader
          :disabled="disabled"
          :getData="assetBelongOrgData"
          v-model="search.asset_belong_org_id"
          labelKey="title"
          @change="asset_belong_org_id_change"
        >
        </model-cascader>
      </template>
      <template slot-scope="{ disabled, size }" slot="collection_dept_idSearch">
        <model-cascader
          :option="{
            emitPath: false,
          }"
          :disabled="disabled"
          :getData="collectionDeptIdData"
          v-model="search.collection_dept_id"
          labelKey="title"
          @change="
            () => {
              form.collection_user_id = ''
            }
          "
        >
        </model-cascader>
      </template>
      <template slot-scope="{ disabled, size }" slot="collection_user_idSearch">
        <model-search-select
          :disabled="disabled"
          v-model="search.collection_user_id"
          format="{name}_{deptName}_{postName}"
          :params="{ tenantId: '000000', deptId: search.collection_dept_id }"
        >
          <template slot-scope="{ item }">
            <span style="float: left">{{ item.name }}{{ item.postName ? `(${item.postName})` : "" }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
          </template>
        </model-search-select>
      </template>
      <template slot-scope="{ disabled, size }" slot="cw_start_dateSearch">
        <el-date-picker
          v-model="search.cw_start_date"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :unlink-panels="true"
        >
        </el-date-picker>
      </template>

      <template slot-scope="{ disabled, size }" slot="storage_location_codeForm">
        <location-cascader
          v-model="form.storage_location_code"
          @change="storage_location_codeChange"
        ></location-cascader>
      </template>
      <template slot-scope="{ disabled, size }" slot="asset_belong_org_idForm">
        <model-cascader
          :option="{
            emitPath: false,
          }"
          :disabled="disabled"
          :getData="assetBelongOrgData"
          v-model="form.asset_belong_org_id"
          labelKey="title"
          @change="
            (ev) => {
              form['asset_belong_org'] = ev.label || ''
            }
          "
        >
        </model-cascader>
      </template>
      <!-- <template slot-scope="{ disabled, size }"  slot="asset_belong_orgForm">
        <model-cascader
          :option="{
            emitPath: false
          }"
          :show-all-levels="false"
          :disabled="disabled"
          :getData="assetBelongOrgData"
          v-model="form.asset_belong_org"
          labelKey="title"
        >
        </model-cascader>
      </template> -->
      <template slot-scope="{ disabled, size }" slot="collection_dept_idForm">
        <model-cascader
          :option="{
            emitPath: false,
          }"
          :show-all-levels="false"
          :disabled="disabled"
          :getData="collectionDeptIdData"
          v-model="form.collection_dept_id"
          labelKey="title"
          @change="
            (ev) => {
              form['collection_dept_name'] = ev.label
              form.collection_user_id = ''
            }
          "
        >
        </model-cascader>
      </template>
      <template slot-scope="{ disabled, size }" slot="asset_good_idForm">
        <model-search-select
          :disabled="disabled"
          v-model="form.asset_good_id"
          model="wp_asset_goods"
          @change="assetGoodChange"
        >
          <template slot-scope="{ item }">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.asset_model }}</span>
          </template>
        </model-search-select>
      </template>
      <template slot-scope="{ disabled, size }" slot="collection_user_idForm">
        <model-search-select
          :disabled="disabled"
          v-model="form.collection_user_id"
          format="{name}_{deptName}_{postName}"
          :params="{ tenantId: '000000', deptId: form.collection_dept_id }"
          :fillModel.sync="form"
          :fills="[{ source: 'name', target: 'collection_user_name' }]"
        >
          <template slot-scope="{ item }">
            <span style="float: left">{{ item.name }}{{ item.postName ? `(${item.postName})` : "" }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
          </template>
        </model-search-select>
      </template>
    </avue-crud>
    <el-dialog title="资产标签" :visible.sync="dialogVisible" width="600px">
      <div ref="AssetTag" class="pad-5" style="margin: 0 auto">
        <AssetTag v-if="dialogVisible" :rows="rows"></AssetTag>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="printTag"
          >打 印</el-button
        >
      </span> -->
    </el-dialog>
    <el-dialog title="导入资产" :visible.sync="dialogVisible1" width="400px">
      <div class="text-center">
        <el-upload
          v-if="dialogVisible1"
          drag
          action="#"
          :limit="1"
          :auto-upload="false"
          accept=".xlsx,.xls"
          ref="importFile"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <basic-form-button
          v-if="dialogType == 'csh'"
          :isForm="false"
          @submit="importFile"
          @cancel="dialogVisible1 = false"
        ></basic-form-button>
        <basic-form-button
          v-if="dialogType == 'plgx'"
          :isForm="false"
          @submit="plgxImportFile"
          @cancel="dialogVisible1 = false"
        ></basic-form-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonListTree,
  getMetaCommonListExport,
} from "@/api/busiMode/metaCommon"
import * as deptService from "@/api/system/dept"
import { j2printer, queryAssetDetailByPage } from "@/api/asset/index.js"
import { getPicListByIds } from "@/api/busiMode/metaCommon"
import * as dictService from "@/api/system/dictbiz"
import AssetTag from "./asset-tag.vue"

import { importAssetsDetail, getAssetDetailInfo, updateImport, exportChild } from "@/api/asset/index.js"

export default {
  components: { AssetTag },
  data() {
    let query = this.$route.query
    return {
      deptParams: {},
      dialogVisible: false,
      dialogVisible1: false,
      rows: [],
      deepOption: {},
      loading: {
        using_unitLoading: false,
        using_personnelLoading: false,
      },
      dictData: {
        using_unit: [],
        using_personnel: [],
      },
      assetCode: "",
      form: {
        system_asset_code: "",
        finance_asset_code: "",
        using_personnel: "",
        asset_belong_org: "",
        collection_dept_id: "",
        collection_user_id: "",
      },
      model: "wisdom_park.wp_asset_detail",
      catalogModel: "wisdom_park.wp_asset_category",
      assetView: "wisdom_park.v_wp_asset_export_stat",
      tableLoading: false,
      downLoading: false,
      tableData: [],
      search: {
        asset_belong_org: "",
        collection_dept_id: "",
        collection_user_id: "",
        cw_start_date: "",
        ...query,
      },
      dialogType: "",
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableSelections: [],
      option: {
        height: "auto",
        searchMenuSpan: 4,
        searchMenuPosition: "left",
        dialogWidth: "80%",
        searchIndex: 2,
        searchIcon: true,
        border: true,
        viewBtn: true,
        align: "center",
        menuWidth: 280,
        labelWidth: 120,
        selection: true,
        reserveSelection: true,
        defaultSort: {
          prop: "cw_start_date",
          order: "ascending",
        },
        column: [
          {
            label: "所属产品",
            prop: "asset_good_id",
            showColumn: false,
            hide: true,
            rules: [{ required: true, message: "请选择所属产品", trigger: "blur" }],
          },
          {
            label: "资产类别",
            prop: "asset_category_code",
            type: "tree",
            addDisabled: false,
            props: {
              label: "asset_category_name",
              value: "id",
            },
            span: 12,
            dicData: [],
            dataType: "string",
            rules: [{ required: true, message: "请选择资产类别", trigger: "blur" }],
            // disabled: true,
          },
          {
            label: "资产编码",
            prop: "system_asset_code",
            rules: [{ required: true, message: "请输入资产编码", trigger: "blur" }],
            width: 150,
            showColumn: true,
            search: true,
            searchSpan: 5,
            span: 12,
            searchLike: true,
            addDisabled: false,
            searchOrder: 9,
            // disabled: true,
          },
          {
            label: "资产名称",
            prop: "asset_name",
            width: 200,
            search: true,
            searchSpan: 5,
            span: 12,
            searchLike: true,
            rules: [{ required: true, message: "请输入资产名称", trigger: "blur" }],
            searchOrder: 8,
            // disabled: true,
          },
          {
            label: "使用日期",
            prop: "cw_start_date",
            showColumn: true,
            type: "date",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            searchSpan: 9,
            search: true,
            span: 12,
            width: 120,
            sortable: "custom",
            searchRange: ["startDate", "endDate"],
            searchOrder: 7,
          },
          {
            label: "财务编码",
            prop: "finance_asset_code",
            minWidth: 180,
            hide: true,
            showColumn: true,
            disabled: true,
            searchSpan: 8,
            span: 12,
            search: false,
          },
          {
            label: "品牌",
            prop: "asset_goods_asset_brand",
            minWidth: 100,
            showColumn: false,
            disabled: true,
            searchSpan: 8,
            hide: true,
            // dicFormatter: (data) => {
            //   if (data.asset_band) {
            //     return data.asset_band;
            //   } else {
            //     return "";
            //   }
            // },
            span: 12,
            search: false,
          },
          {
            label: "资产型号",
            prop: "asset_model",
            span: 12,
            disabled: true,
            showColumn: false,
            width: 160,
            hide: true,
          },
          {
            label: "资产规格",
            prop: "asset_goods_asset_norms",
            showColumn: true,
            span: 12,
            disabled: true,
            hide: true,
          },
          {
            label: "计量单位",
            prop: "asset_goods_unit_of_measure",
            showColumn: true,
            span: 12,
            disabled: true,
            hide: true,
          },
          {
            label: "产品单价(元)",
            prop: "asset_goods_asset_vlaue",
            showColumn: false,
            span: 12,
            disabled: true,
            hide: true,
          },
          {
            label: "预使用年限",
            prop: "asset_goods_expected_useful_life",
            width: 200,
            showColumn: true,
            type: "number",
            span: 12,
            disabled: true,
            hide: true,
          },
          {
            label: "资产价格(元)",
            prop: "asset_vlaue",
            minWidth: 100,
            align: "right",
            showColumn: true,
            searchSpan: 8,
            span: 12,
            search: false,
          },
          {
            label: "折旧率",
            prop: "depreciation_rate",
            hide: true,
            showColumn: true,
            append: "%",
            span: 12,
          },
          {
            label: "资产数量",
            prop: "asset_quantity",
            showColumn: true,
            hide: true,
          },
          {
            label: "所属机构",
            prop: "asset_belong_org",
            showColumn: true,
            display: false,
            // search: true,
            width: 200,
            span: 12,
            searchLike: true,
            searchSpan: 8,
          },
          {
            label: "所属机构",
            prop: "asset_belong_org_id",
            dicData: [],
            type: "select",
            width: 200,
            showColumn: false,
            hide: true,
            props: {
              label: "title",
            },
            rules: [
              {
                required: false,
                message: "请选择机构",
                trigger: "click",
              },
            ],
          },
          // {
          //   label: "所属机构",
          //   prop: "asset_belong_org",
          //   showColumn: true,
          //   type: "select",
          //   dicUrl: "api/jida-meta/common-ops/list/wisdom_park.wp_asset_detail",
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   search: true,
          //   searchSpan: 8,
          //   width: 200,
          //   span: 12,
          // },
          {
            label: "使用部门",
            prop: "collection_dept_name",
            showColumn: true,
            display: false,
            width: 200,
            span: 12,
          },
          {
            label: "使用部门",
            prop: "collection_dept_id",
            type: "tree",
            showColumn: false,
            search: true,
            hide: true,
            searchSpan: 6,
            width: 200,
            span: 12,
          },
          {
            label: "使用人员",
            prop: "collection_user_name",
            showColumn: true,
            display: false,
            width: 120,
            span: 12,
          },
          {
            label: "使用人员",
            prop: "collection_user_id",
            showColumn: false,
            search: true,
            hide: true,
            searchSpan: 6,
            width: 200,
            span: 12,
          },
          {
            label: "位置",
            prop: "location_name",
            display: false,
            width: 250,
            searchSpan: 6,
            span: 12,
            search: true,
            searchLike: true,
          },

          {
            label: "当前状态",
            prop: "asset_current_status",
            type: "select",
            dicUrl: dictService.getUrl("AssetCurrentStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            span: 12,
            searchSpan: 5,
            search: true,
            width: 120,
            rules: [{ required: true, message: "请选择当前状态", trigger: "blur" }],
            searchOrder: 6,
          },
          // {
          //   label: "购买日期",
          //   prop: "purchase_date",
          //   showColumn: true,
          //   type: "date",
          //   width: 200,
          //   valueFormat: "yyyy-MM-dd HH:mm:ss",
          //   span: 12,
          //   hide: true,
          // },
          {
            label: "财务入账日期",
            prop: "cw_rz_date",
            showColumn: true,
            type: "date",
            width: 200,
            valueFormat: "yyyy-MM-dd",
            span: 12,
            hide: true,
          },

          {
            label: "存放位置",
            prop: "storage_location_code",
            width: 200,
            showColumn: true,
            span: 12,
            hide: true,
          },
          {
            label: "详细地址",
            prop: "address_detail",
            // display: false,
            width: 250,
            span: 12,
            searchSpan: 8,
            // search: true,
            searchLike: true,
          },

          {
            label: "备注",
            prop: "remarks",
            width: 200,
            showColumn: true,
            span: 24,
            type: "textarea",
            hide: true,
          },
          {
            label: "资产照片",
            prop: "asset_pictures",
            hide: true,
            showColumn: false,
            type: "upload",
            listType: "picture-card",
            span: 24,
            value: [],
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
          {
            label: "标签照片",
            prop: "asset_label_pictures",
            hide: true,
            showColumn: false,
            type: "upload",
            listType: "picture-card",
            span: 24,
            value: [],
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      orders: { sort: "ASC" },
      importTemplateDownUrl: "",
      importTeamplateDownFileName: "",
    }
  },
  props: {
    queryParams: {
      type: Object,
      default() {
        return null
      },
    },
    requireQueryParams: {
      type: Boolean,
      default: false,
    },
    calcHeight: {
      type: Number,
      default: 54,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    tableOption: {
      type: Object,
      default() {
        return null
      },
    },
  },
  watch: {
    queryParams() {
      this.page.currentPage = 1
      this.onLoad()
    },
  },
  mounted() {
    this.initData()
    this.initdeptData()
    this.deepOption = JSON.parse(JSON.stringify(this.option.column))
    this.getImportTemplateDownUrl()
  },
  methods: {
    importTamplateDownload() {
      this.downFile(this.importTemplateDownUrl, this.importTeamplateDownFileName)
    },
    getImportTemplateDownUrl() {
      dictService
        .getDictionary({
          code: "TEMPLATE_DOWNLOAD_URL",
        })
        .then((res) => {
          ;(res.data.data || []).forEach((item) => {
            if (item.dictValue === "DATA_ASSET_IMPORT") {
              this.importTemplateDownUrl = item.dictKey
              this.importTeamplateDownFileName = item.remark || ""
            }
          })
        })
    },
    sortChange({ column, prop, order }) {
      this.orders = {
        sort: order === "descending" ? "DESC" : "ASC",
      }
      this.onLoad()
    },
    tableSelection(ev) {
      this.tableSelections = ev
    },
    printer() {
      if (this.tableSelections.length < 1) {
        this.$message.warning("请至少选中一条资产")
        return
      }
      this.$confirm("确定打印选中资产标签?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = []
        this.tableSelections.forEach((item) => {
          data.push({
            asset_name: item.asset_name || "",
            system_asset_code: item.system_asset_code || "",
            asset_model: item.asset_model || "",
            collection_dept_name: item.collection_dept_name || "",
            location_name: item.location_name || "",
            address_detail: item.address_detail || "",
            cw_start_date: item.cw_start_date || "",
            asset_belong_org: item.asset_belong_org || "",
            collection_user_name: item.collection_user_name || "",
            id: `${item.id}`,
          })
        })
        j2printer(data)
          .then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            })
          })
          .catch((e) => {
            this.$message({
              type: "error",
              message: "打印失败，未连接到打印适配程序，请联系管理员!",
            })
          })
      })
    },
    assetGoodChange(ev) {
      if (ev.value) {
        let data = ev.data.find((item) => item[ev.valueKey] === ev.value)
        if (data) {
          this.form["asset_name"] = data["name"]
          ;[
            "asset_brand",
            "asset_category_code",
            "asset_model",
            "asset_norms",
            "unit_of_measure",
            "asset_vlaue",
            "expected_useful_life",
            "finance_asset_code",
            "import_nums",
          ].forEach((key) => {
            if (typeof this.findObject(this.option.column, key) === "object") {
              this.form[key] = data[key]
            }
            if (typeof this.findObject(this.option.column, `asset_goods_${key}`) === "object") {
              this.form[`asset_goods_${key}`] = data[key]
            }
          })
        }
      }
    },
    asset_belong_org_id_change(ev) {},
    collectionDeptIdData(ev, done) {
      deptService.getDeptTree().then((res) => {
        done(res.data.data || [])
      })
    },
    assetBelongOrgData(ev, done) {
      deptService.getDeptTree().then((res) => {
        done(res.data.data || [])
      })
    },
    uploadMethod(type) {
      this.dialogType = type
      this.dialogVisible1 = "true"
    },
    initdeptData() {
      deptService.getDeptTree().then((res) => {
        const column = this.findObject(this.option.column, "asset_belong_org_id")
        column.dicData = res.data.data
      })
    },
    exportTable() {
      // this.getMetaCommonListExport();
      if (this.queryParams.asset_category_code) {
        this.downLoading = true
        exportChild({
          asset_category_code: this.queryParams.asset_category_code,
        }).then((res) => {
          this.getMetaCommonListExport(res.data.data)
        })
      } else {
        this.getMetaCommonListExport([])
      }
    },
    getMetaCommonListExport(asset_category_code_ids) {
      let wheres = []
      let searchParams = {
        ...this.queryParams,
        ...this.search,
      }
      Object.keys(searchParams).forEach((key) => {
        if (key[0] !== "$" && (searchParams[key] ?? "") !== "" && key !== "asset_category_code") {
          const column = this.findObject(this.option.column, key)
          let itemWhere = { field: key, value: searchParams[key] }

          if (key == "cw_start_date" && searchParams[key].length > 0) {
            let item1 = { field: "cw_start_date", value: searchParams[key][0], op: "GE" }
            let item2 = { field: "cw_start_date", value: searchParams[key][1], op: "LE" }
            wheres.push(item1)
            wheres.push(item2)
          } else {
            if (column) {
              if (column.searchLike) {
                itemWhere["op"] = "LIKE"
              }
            }
            wheres.push(itemWhere)
          }
        }
      })
      if (asset_category_code_ids.length) {
        wheres.push({
          field: "asset_category_code",
          value: asset_category_code_ids,
          op: "IN",
        })
      }
      this.downLoading = true
      getMetaCommonListExport(this.assetView, {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
        orders: [{ field: "cw_start_date", sort: "ASC" }],
      })
        .then((res) => {
          this.downLoading = false
          let url = window.URL.createObjectURL(new Blob([res.data]))
          let link = document.createElement("a")
          link.style.display = "none"
          link.href = url
          link.download = "资产明细" + ".xlsx"
          document.body.appendChild(link)
          link.click()
        })
        .catch((e) => {
          this.downLoading = false
        })
    },
    storage_location_codeChange(data) {
      this.form["location_name"] = data.label
    },
    using_personnelChange(ev) {
      if (ev && ev.item) {
        this.form["collection_user_name"] = ev.item.name
        this.form["collection_dept_name"] = ev.item.deptName
        this.form["collection_dept_id"] = ev.item.deptId
      } else {
        this.form["collection_dept_id"] = ""
        this.form["collection_dept_name"] = ""
      }
    },
    importFile(done) {
      if (!this.$refs.importFile.uploadFiles.length) {
        this.$message.error("请选择文件")
        done()
      } else {
        importAssetsDetail(this.$refs.importFile.uploadFiles[0].raw)
          .then((res) => {
            this.$message.success("操作成功")
            this.dialogVisible1 = false
            done()
            this.onLoad()
          })
          .catch((e) => {
            done()
          })
      }
    },
    plgxImportFile(done) {
      if (!this.$refs.importFile.uploadFiles.length) {
        this.$message.error("请选择文件")
        done()
      } else {
        updateImport(this.$refs.importFile.uploadFiles[0].raw)
          .then((res) => {
            this.$message.success("操作成功")
            this.dialogVisible1 = false
            done()
            this.onLoad()
          })
          .catch((e) => {
            done()
          })
      }
    },

    printTag() {
      let dom = this.$refs.AssetTag
      let cloneDom = dom.cloneNode(true)
      this.$Print(cloneDom)
    },
    showAssetTag(row) {
      this.rows = [row]
      this.dialogVisible = true
    },
    getMetaCommonList(code, prop, params) {
      let obj = {}
      Object.keys(params).forEach((ele) => {
        if (!ele.includes("$")) {
          obj[ele] = params[ele]
        }
      })
      return getMetaCommonList(`wisdom_park.${code}`, {
        current: 1,
        size: 500,
        obj,
      }).then((res) => {
        if (res.data && res.data.data && prop) {
          this.dictData[prop] = res.data.data.records
        }
        return res
      })
    },
    searchModel(queryStr, modelCode, prop, searchFieldName, inputParams) {
      let params = {}
      if (queryStr) {
        params = {
          wheres: [
            searchFieldName
              ? { field: searchFieldName, value: queryStr }
              : { field: "name", op: "LIKE", value: queryStr },
          ],
        }
      }
      if (inputParams) {
        params["wheres"] = params.wheres || []
        Object.keys(inputParams).forEach((key) => {
          params.wheres.push({
            field: key,
            value: inputParams[key],
          })
        })
      }
      this.loading[prop + "Loading"] = true
      this.getMetaCommonList(modelCode, prop, params).then((res) => {
        this.loading[prop + "Loading"] = false
      })
    },
    usingUnitChange() {
      this.form["using_personnel"] = ""
      this.dictData.using_personnel = []
      if (this.form["using_unit"]) {
        this.searchModel("", "wp_employee", "using_personnel", null, {
          company_id: this.form["using_unit"],
        })
      }
    },
    getPath(data, value) {
      let resultPath = []
      let getPath = (data, pathObj) => {
        pathObj = pathObj || {
          has: false,
          path: [],
        }
        data.forEach((item) => {
          if (item.id === value) {
            pathObj.path.push(item)
            pathObj.has = true
          } else {
            if (item.children) {
              pathObj.path.push(item)
              getPath(item.children, pathObj)
            }
          }
        })
        return pathObj
      }
      if (data) {
        data.forEach((item) => {
          if (item.id === value) {
            resultPath.push(item)
          } else {
            if (item.children) {
              let path = getPath(item.children, {
                has: false,
                path: [item],
              })
              if (path && path.has) {
                resultPath = path.path
              }
            }
          }
        })
      }
      return resultPath
    },
    initData(type) {
      getMetaCommonListTree(this.catalogModel, {
        labelField: "asset_category_name",
        valueField: "id",
        releaseField: "parent_id",
        isLazy: false,
      }).then((res) => {
        const column = this.findObject(this.option.column, "asset_category_code")
        column.dicData = res.data.data
      })
    },
    treeLoad(tree, treeNode, resolve) {
      const parentId = tree.id
      getMetaCommonListTree(this.catalogModel, {
        labelField: "asset_category_name",
        valueField: "id",
        releaseField: "parent_id",
        rootValue: parentId,
      }).then((res) => {})
    },
    searchChange(form, done) {
      this.page.currentPage = 1
      this.onLoad(done)
    },
    onLoad(done) {
      this.tableData = []
      if (this.requireQueryParams && !Object.keys(this.queryParams).length) {
        return []
      }
      let wheres = {}
      let searchParams = {
        ...this.queryParams,
        ...this.search,
      }
      Object.keys(searchParams).forEach((key) => {
        if ((searchParams[key] ?? "") !== "" && key[0] !== "$") {
          const column = this.findObject(this.option.column, key)
          if (column && column.searchRange) {
            if (searchParams[key] && Array.isArray(searchParams[key])) {
              column.searchRange.forEach((name, index) => {
                if (searchParams[key][index]) {
                  wheres[name] = searchParams[key][index]
                }
              })
            }
          } else {
            if (searchParams[key]) {
              wheres[key] = searchParams[key]
            }
            // let itemWhere = { field: key, value: searchParams[key] };
            // if (column) {
            //   if (column.searchLike) {
            //     itemWhere["op"] = "LIKE";
            //   }

            // }
            // wheres.push(itemWhere);
          }
        }
      })
      this.tableLoading = true
      queryAssetDetailByPage({
        current: this.page.currentPage,
        size: this.page.pageSize,
        ...wheres,
        // ...this.orders,
        orders: [
          { field: "create_time", sort: "DESC" },
          { field: "cw_start_date", sort: "DESC" },
        ],
        // orders: this.orders
      })
        .then((res) => {
          this.tableLoading = false
          this.tableData = res.data.data.records || []
          this.page.total = res.data.data.total || 0
          done && done()
        })
        .catch((e) => {
          this.tableLoading = false
          done && done()
        })
    },
    saveTableForm(row, done, loading) {
      let body = {}
      Object.keys(row).forEach((keyName) => {
        if (keyName.indexOf("$") < 0 && keyName !== "id" && keyName.indexOf("asset_goods_") === -1) {
          body[keyName] = row[keyName]
        }
      })
      ;["asset_pictures", "asset_label_pictures"].forEach((name) => {
        if (body[name] && Array.isArray(body[name])) {
          let ids = []
          body[name].forEach((item) => {
            ids.push(item.label)
          })
          body[name] = ids.join(",")
        }
      })
      let idNames = ["locationBuildId", "locationFloorId", "locationRoomId"]
      body["storage_location_code"] &&
        body["storage_location_code"].forEach((item, index) => {
          let name = idNames[index].replace(/([A-Z])/g, "_$1").toLowerCase()
          body[name] = item
        })
      ;["asset_goods", "storage_location_code", "using_unit_name", "is_deleted"].forEach((name) => {
        delete body[name]
      })
      // ['system_asset_code' , 'finance_asset_code'].forEach(
      //   name => {
      //     body[name] = `${this.assetCode}${body[name]}`;
      //   }
      // )
      this.option.column.forEach((item) => {
        if (item["disabled"]) {
          delete body[item.prop]
        }
      })
      if (row.id) {
        body = {
          update: body,
          primaryKeyValue: row.id,
          primaryKey: "id",
        }
      } else {
        body = {
          values: body,
        }
      }
      ;(row.id ? updateMetaCommon : saveMetaCommon)(this.model, body)
        .then((res) => {
          this.responeHandle(res, done, loading)
        })
        .catch((e) => {
          loading()
        })
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done()
        this.onLoad()
        this.$message.success(res.data.msg)
      } else {
        loading && loading()
        this.$message.error(res.data.msg)
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return delMetaCommon(this.model, { pk: "id", ids: [row.id] })
        })
        .then(() => {
          this.onLoad()
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        })
    },
    beforeClose(done) {
      this.parentId = ""
      const column = this.findObject(this.option.column, "asset_category_code")
      column.value = ""
      column.addDisabled = false
      column.disabled = false
      this.form = {
        system_asset_code: "",
        finance_asset_code: "",
        using_personnel: "",
      }
      done()
    },
    getPicListByIds(ids) {
      return new Promise((resolve, reject) => {
        let attachment_ids = ids ? ids.split(",") : []
        let fileList = []
        if (attachment_ids && attachment_ids.length) {
          getPicListByIds(attachment_ids).then((res) => {
            if (res && res.data && res.data.data) {
              res.data.data.forEach((item) => {
                let fileItem = {
                  label: item.id,
                  value: item.link,
                }
                fileList.push(fileItem)
              })
            }
            resolve(fileList)
          })
        } else {
          resolve(fileList)
        }
      })
    },
    beforeOpen(done, type) {
      this.option.column = JSON.parse(JSON.stringify(this.deepOption))
      if (type === "add") {
        this.option.column.forEach((item) => {
          item.disabled = item.addDisabled !== undefined ? item.addDisabled : !!item.disabled
        })
      }
      if (type === "add" && this.queryParams["asset_category_code"]) {
        const column = this.findObject(this.option.column, "asset_category_code")

        column.value = this.queryParams["asset_category_code"]
        this.form["asset_category_code"] = this.queryParams["asset_category_code"]
        column.disabled = true
      }
      if (["add", "edit", "view"].includes(type)) {
        this.initData()
      }
      if (["edit", "view"].includes(type)) {
        getAssetDetailInfo(this.form.id).then((res) => {
          let storage_location_code = []
          let form = {
            using_unit_name: "",
          }
          let assetGoods = res.data.data.assetGoods
          Object.keys(res.data.data).forEach((keyName) => {
            let name = keyName.replace(/([A-Z])/g, "_$1").toLowerCase()
            form[name] = res.data.data[keyName]
          })
          Object.keys(assetGoods).forEach((keyName) => {
            let name = keyName.replace(/([A-Z])/g, "_$1").toLowerCase()
            form[`asset_goods_${name}`] = assetGoods[keyName]
          })
          ;["locationBuildId", "locationFloorId", "locationRoomId"].forEach((keyName) => {
            let name = keyName.replace(/([A-Z])/g, "_$1").toLowerCase()
            if ((form[name] ?? "") !== "") {
              storage_location_code.push(form[name])
            }
          })
          form["storage_location_code"] = storage_location_code
          this.form = form
          // if((this.form['using_unit'] ?? '') !== ''){
          //   this.searchModel('' , 'wp_company' , 'using_unit' , null , {
          //     id: this.form['using_unit']
          //   })
          //   this.searchModel('' , 'wp_employee' , 'using_personnel' , null , {
          //     'company_id': this.form['using_unit']
          //   });
          // }
          ;["asset_pictures", "asset_label_pictures"].forEach((name) => {
            if (this.form[name]) {
              this.getPicListByIds(this.form[name]).then((fileList) => {
                this.form[name] = fileList
              })
            } else {
              this.form[name] = []
            }
          })
        })
      }
      done()
    },
  },
}
</script>
