<!--
 * @Description: 
 * @Author: Niklaus
 * @Date: 2024-04-23 10:04:57
 * @LastEditors: Niklaus
 * @LastEditTime: 2025-04-01 10:30:52
-->
<template>
  <div>
    <div v-for="item of rows" class="w-100p assets-tag-table mar-b-20">
      <table class="w-100p bor">
        <tr class="bor">
          <th colspan="3" class="lh-40 text-center"><b>固定资产标签</b></th>
        </tr>
        <tr class="bor">
          <td class="bor">资产编号</td>
          <td class="bor">{{ item.system_asset_code }}</td>
          <td rowspan="5" class="pad-30 w-128" v-html="getAraleQrcode(item)"></td>
        </tr>
        <tr class="bor">
          <td class="bor">资产名称</td>
          <td class="bor">{{ item.asset_name }}</td>
        </tr>
        <tr class="bor">
          <td class="bor">资产型号</td>
          <td class="bor">{{ item.asset_model }}</td>
        </tr>
        <tr class="bor">
          <td class="bor">使用部门</td>
          <td class="bor">{{ item.using_unit }}</td>
        </tr>
        <tr class="bor">
          <td class="bor">使用日期</td>
          <td class="bor">{{ item.cw_start_date }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    rows: [],
  },
  mounted() {},
  methods: {
    getAraleQrcode(row) {
      let svg = new AraleQRCode({
        render: "svg",
        text: row.id,
        size: 128,
      })
      return svg.outerHTML
    },
  },
}
</script>
<style lang="scss" scoped>
.assets-tag-table {
  td {
    padding: 0 15px;
  }
}
</style>
