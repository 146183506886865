<!--
 * @Description: 更新 每日账单表（wp_receipt_wp_receipt_amount_day）
 * @Author: Niklaus
 * @Date: 2025-03-03 15:17:02
 * @LastEditors: Niklaus
 * @LastEditTime: 2025-03-07 15:07:48
-->
<template>
  <div>
    <el-container class="h-100p">
      <basic-container style="width: 100%">
        <div class="el-card mar-b-15 is-never-shadow avue-crud__search">
          <div class="el-card__body">
            <el-form label-width="120px" :model="form" :rules="rules" ref="ruleForm">
              <el-form-item label="账单编号" prop="receiptNo">
                <el-input v-model="form.receiptNo" placeholder="请输入账单编号"></el-input>
              </el-form-item>
              <el-form-item label="审批流ID" prop="processInstanceId">
                <el-input v-model="form.processInstanceId" placeholder="请输入审批流ID"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="onSubmit">更新后台</el-button>
                <el-button>取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </basic-container>
    </el-container>
  </div>
</template>

<script>
import { updateDailyAccount } from "@/api/finance/index"

export default {
  data() {
    return {
      form: {
        receiptNo: "",
        processInstanceId: "",
      },
      rules: {
        receiptNo: [{ required: true, message: "请输入账单编号", trigger: "blur" }],
        processInstanceId: [{ required: true, message: "请输入审批流ID", trigger: "blur" }],
      },
    }
  },

  methods: {
    onSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          updateDailyAccount(this.form).then((res) => {
            if (res.data.success) {
              this.$message.success("更新成功")
              Object.keys(this.form).forEach((key) => {
                this.form[key] = ""
              })
            } else {
              this.$message.error("请求失败")
            }
          })
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
  },
}
</script>

<style scoped></style>
