<!-- 
 * @Description: 系统平账（账单） - 后台处理数据临时使用
 * @Author: Niklaus
 * @Date: 2025-03-01 08:45:42
 * @LastEditors: Niklaus
 * @LastEditTime: 2025-03-08 11:29:18
-->
<template>
  <el-container class="h-100p">
    <basic-container style="width: 100%">
      <div class="el-card mar-b-15 is-never-shadow avue-crud__search">
        <div class="el-card__body">
          <el-form label-width="80px" ref="ruleForm">
            <el-row>
              <el-col :span="5">
                <el-form-item label="账单编号">
                  <el-input placeholder="请输入账单编号" v-model="params.receiptNo"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item label="企业名称">
                  <el-input placeholder="请输入企业名称" v-model="params.orgName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="账单状态">
                  <el-select v-model="params.status">
                    <el-option value="1" label="待缴纳"></el-option>
                    <el-option value="2" label="已缴纳"></el-option>
                    <el-option value="3" label="逾期"></el-option>
                    <el-option value="4" label="部分缴纳"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item label="类型">
                  <el-select v-model="params.expenseAccountCode">
                    <el-option value="1007" label="保证金"></el-option>
                    <el-option value="1000" label="租金|综合服务费"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item>
                  <el-button type="primary" @click="handleSearch">搜索</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>

      <avue-crud
        ref="table"
        :data="tableData"
        :page.sync="page"
        :option="option"
        :table-loading="tableLoading"
        :search.sync="search"
        @on-load="onLoad"
        @search-change="searchChange"
        @refresh-change="onLoad"
        @selection-change="selectedListChange"
      >
        <template slot-scope="{ size }" slot="menuLeft">
          <el-button icon="el-icon-notebook-1" type="primary" size="small" @click="handleBalance">系统平账</el-button>
        </template>

        <template slot-scope="scope" slot="menu">
          <el-button size="mini" @click="openPrefSuppDialog(scope.row)">补录优惠减免</el-button>
          <el-button size="mini" type="warn" @click="clearPay(scope.row.receiptNo)">清空缴费记录</el-button>
        </template>
      </avue-crud>
    </basic-container>

    <el-dialog title="优惠补录" :visible.sync="prefSuppDialogVisible" width="40%" append-to-body>
      <el-form v-if="prefSuppDialogVisible" ref="prefSuppForm" :model="prefSuppForm" size="small" label-width="120px">
        <el-form-item
          :rules="[{ required: true, message: '请选择优惠政策', trigger: 'blur' }]"
          label="优惠政策"
          prop="reductionPolicyId"
        >
          <el-select v-model="prefSuppForm.reductionPolicyId" placeholder="请选择优惠政策">
            <el-option
              v-for="item in reducitionList"
              :key="item.reduction_policy_id"
              :label="item.reduction_policy_title"
              :value="item.reduction_policy_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-form-item
            :rules="[{ required: true, message: '请输入优惠金额', trigger: 'blur' }]"
            label="优惠金额"
            prop="reductionAmount"
          >
            <el-input
              v-model.number="prefSuppForm.reductionAmount"
              type="number"
              placeholder="请输入优惠金额"
            ></el-input>
          </el-form-item>
          <div class="pull-right">
            <el-button size="small" @click="prefSuppDialogVisible = false">取消</el-button>
            <el-button size="small" @click="savePrefSupp" type="primary">确认</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
import { getList, balanceReceipt, clearPay, getDetail } from "@/api/finance/index.js"
import * as dictService from "@/api/system/dictbiz"
import { additionalAmount } from "@/api/finance/index.js"
import { getMetaCommonList } from "@/api/busiMode/metaCommon"

export default {
  data() {
    return {
      params: {
        contractCode: "",
        receiptNo: "",
        orgName: "",
        subjectId: "",
        status: "",
        expenseAccountCode: "",
      },
      reducitionList: [],
      prefSuppDialogVisible: false,
      prefSuppForm: {},
      model: "wisdom_park.wp_receipt",
      selectedList: [],
      tableLoading: false,
      tableData: [],
      search: {},
      item: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 50,
        currentPage: 1,
        total: 0,
      },
      option: {
        height: "auto",
        searchMenuPosition: "right",
        searchMenuSpan: 3,
        searchSpan: 6,
        border: true,
        index: true,
        selection: true,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        menuWidth: 240,
        header: true,
        column: [
          {
            label: "账单对象",
            prop: "subject",
            minWidth: 200,
          },
          {
            label: "账单编号",
            prop: "receiptNo",
            width: 180,
          },
          {
            label: "关联合同",
            prop: "contractNo",
            minWidth: 150,
          },
          {
            label: "类型",
            prop: "feeItemName",
            width: 120,
          },
          {
            label: "账单状态",
            prop: "status",
            type: "select",
            dataType: "number",
            dicUrl: dictService.getUrl("ReceiptStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 80,
          },
          {
            label: "应收金额",
            prop: "amountReceivable",
            width: 100,
          },
          {
            label: "补贴金额",
            prop: "amountReduction",
            width: 100,
          },
          {
            label: "实收金额",
            prop: "amountReceived",
            width: 100,
          },
          {
            label: "开始时间",
            prop: "periodStartTime",
            width: 100,
            type: "date",
            format: "yyyy-MM-dd",
          },
          {
            label: "结束时间",
            prop: "periodEndTime",
            width: 100,
            type: "date",
            format: "yyyy-MM-dd",
          },

          {
            label: "应缴日期",
            prop: "receivableDate",
            width: 100,
            type: "date",
            format: "yyyy-MM-dd",
          },
        ],
      },
    }
  },

  methods: {
    /**
     * 执行搜索
     */
    handleSearch() {
      if (this.params.orgName) {
        this.getCompany().then((res) => {
          console.log(res.data)
          if (res.data.success) {
            if (res.data.data.records.length > 1) {
              this.$message.error("企业数据为多条，请明确企业名称")
            } else {
              this.params.subjectId = res.data.data.records[0].id
              this.onLoad()
            }
          } else {
            this.$message.error("未查询到企业信息")
          }
        })
      } else {
        this.onLoad()
      }
    },
    /**
     * 加载列表数据
     */
    onLoad() {
      this.tableData = []
      let params = {
        current: this.page.currentPage,
        size: this.page.pageSize,
        ...this.params,
      }
      this.tableLoading = true

      getList(params)
        .then((res) => {
          this.tableLoading = false
          this.tableData = res.data.data.records
          this.page.total = res.data.data.total
        })
        .catch((e) => {
          this.tableLoading = false
        })
    },

    searchChange(params, done) {
      this.onLoad()
    },
    /**
     * 监听选中的数据列表
     */
    selectedListChange(list) {
      console.log(list)
      this.selectedList = list.map((item) => {
        let obj = { receiptNo: item.receiptNo, status: item.status, amount: item.amountReceivable }
        return obj
      })
      this.$nextTick(() => {
        console.log(this.selectedList)
      })
    },
    /**
     * 获取优惠政策列表
     */
    getReducitions(id) {
      getMetaCommonList("wisdom_park.wp_reduction_policy_company", {
        current: 1,
        size: 100,
        wheres: [{ field: "company_id", value: id }],
      }).then((res) => {
        if (res.data && res.data.code === 200) {
          this.reducitionList = res.data.data.records
        }
      })
    },

    /** 打开疫情补录Modal */
    openPrefSuppDialog(item) {
      this.item = item
      this.getReducitions(item.subjectId)
      this.prefSuppDialogVisible = true
    },
    /**
     * 提交优惠减免数据
     */
    savePrefSupp() {
      this.$refs.prefSuppForm.validate((valid) => {
        if (valid) {
          let reductionPolicyName = ""
          this.reducitionList.forEach((e) => {
            if (e.reduction_policy_id == this.prefSuppForm.reductionPolicyId) {
              reductionPolicyName = e.reduction_policy_title
            }
          })
          let obj = {
            reductionPolicyId: this.prefSuppForm.reductionPolicyId, //优惠政策ID
            reductionPolicyName: reductionPolicyName, //优化政策名称
            receiptNo: this.item.receiptNo, //账单编号
            reductionAmount: this.prefSuppForm.reductionAmount, //优惠金额
          }

          additionalAmount(obj).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "操作成功!",
              })
              this.onLoad()
              this.prefSuppDialogVisible = false
            } else {
              this.$message({
                type: "error",
                message: res.data.msg,
              })
            }
          })
        } else {
          cosnole.log("form数据错误")
        }
      })
    },

    handleBalance() {
      this.selectedList.length == 0
        ? this.$message.info("至少选择一条数据")
        : this.$confirm("确认系统自动将选中账单平账吗？", "提示", {
            confirmButtonText: "确 认",
            cancelButtonText: "取 消",
          })
            .then(() => {
              this.doBalance()
            })
            .catch(() => console.info("操作取消"))
    },

    doBalance() {
      console.log(" -- 执行系统平账 --")
      console.log(this.selectedList)
      // return
      /** 暂时只处理 待缴纳 */
      let arrPromise = []
      this.selectedList.forEach((item) => {
        getDetail({ receiptNo: item.receiptNo }).then((res) => {
          if (res.data.success) {
            arrPromise.push(balanceReceipt({ receiptNo: item.receiptNo, amount: res.data.data.paidAmount }))
          }
        })
      })
      let tt = []
      Promise.all(arrPromise)
        .then((res) => {
          res.forEach((item, index) => {
            if (!item.data.success) {
              tt.push(this.list[index].receiptNo)
            }
          })
          if (tt.length > 0) {
            this.$message.error(tt.join(",") + " 账单更新错误")
          } else {
            this.$message.success("更新成功")
            this.selectedList = []
          }
        })
        .finally(() => {
          this.onLoad()
        })
    },

    clearPay(receiptNo) {
      this.$confirm("确认清空此账单的缴费记录吗？", "提示", {
        confirmButtonText: "确 认",
        cancelButtonText: "取 消",
      })
        .then(() => {
          console.log("111111111", receiptNo)
          clearPay({ receiptNo: receiptNo }).then((res) => {
            if (res.data.success) {
              this.$message.success("已清空记录")
              this.onLoad()
            } else {
              this.$message.warn("操作失败")
            }
          })
        })
        .catch(() => console.info("操作取消"))
    },

    /** 根据企业名称查询企业列表 */
    getCompany() {
      return getMetaCommonList("wisdom_park.v_wp_company_building_code_stat", {
        wheres: [{ field: "name", value: this.params.orgName, op: "LIKE" }],
      })
    },
  },
}
</script>

<style scoped></style>
