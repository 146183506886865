<template>
  <div>
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="option"
      :table-loading="tableLoading"
      :before-open="beforeOpen"
      :before-close="beforeClose"
      :search.sync="search"
      v-model="form"
      @search-change="searchChange"
      @on-load="onLoad()"
      @refresh-change="onLoad()"
      @row-save="saveTableForm"
      @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
      @row-del="rowDel"
      @selection-change="tableSelection"
      @sort-change="sortChange">
      <template slot="menu" slot-scope="{ size, row }">
        <el-button type="text" :size="size || 'small'" icon="el-icon-collection-tag" @click="showAssetTag(row)">查看标签</el-button>
      </template>

      <!-- <template slot-scope="{ disabled, size }" slot="assetBelongOrgIdSearch">
        <model-cascader
          :disabled="disabled"
          :getData="assetBelongOrgData"
          v-model="search.asset_belong_org_id"
          labelKey="title"
          @change="asset_belong_org_id_change"
        >
        </model-cascader>
      </template> -->
      <!-- <template slot-scope="{ disabled, size }" slot="collection_dept_idSearch">
        <model-cascader
          :option="{
            emitPath: false,
          }"
          :disabled="disabled"
          :getData="collectionDeptIdData"
          v-model="search.collection_dept_id"
          labelKey="title"
          @change="
            () => {
              form.collection_user_id = ''
            }
          "
        >
        </model-cascader>
      </template> -->
      <!-- <template slot-scope="{ disabled, size }" slot="collection_user_idSearch">
        <model-search-select
          :disabled="disabled"
          v-model="search.collection_user_id"
          format="{name}_{deptName}_{postName}"
          :params="{ tenantId: '000000', deptId: search.collection_dept_id }"
        >
          <template slot-scope="{ item }">
            <span style="float: left">{{ item.name }}{{ item.postName ? `(${item.postName})` : "" }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
          </template>
        </model-search-select>
      </template> -->
      <!-- <template slot-scope="{ disabled, size }" slot="cw_start_dateSearch">
        <el-date-picker
          v-model="search.cw_start_date"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :unlink-panels="true"
        >
        </el-date-picker>
      </template> -->

      <!-- <template slot-scope="{ disabled, size }" slot="storage_location_codeForm">
        <location-cascader
          v-model="form.storage_location_code"
          @change="storage_location_codeChange"
        ></location-cascader>
      </template> -->
      <template slot-scope="{ disabled, size }" slot="assetBelongOrgIdForm">
        <model-cascader
          :option="{
            emitPath: false,
          }"
          :disabled="disabled"
          :getData="assetBelongOrgData"
          v-model="form.assetBelongOrgId"
          labelKey="title"
          @change="
            (ev) => {
              form['assetBelongOrg'] = ev.label || ''
            }
          ">
        </model-cascader>
      </template>
      <template slot-scope="{ disabled, size }" slot="collectionDeptIdForm">
        <model-cascader
          :option="{
            emitPath: false,
          }"
          :show-all-levels="false"
          :disabled="disabled"
          :getData="collectionDeptIdData"
          v-model="form.collectionDeptId"
          labelKey="title"
          @change="
            (ev) => {
              form['collectionDeptName'] = ev.label
              form.collectionUserId = ''
            }
          ">
        </model-cascader>
      </template>
      <template slot-scope="{ disabled, size }" slot="assetGoodIdForm">
        <model-search-select :disabled="disabled" v-model="form.assetGoodId" model="wp_asset_goods" @change="assetGoodChange">
          <template slot-scope="{ item }">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.asset_model }}</span>
          </template>
        </model-search-select>
      </template>
      <template slot-scope="{ disabled, size }" slot="collectionUserIdForm">
        <model-search-select :disabled="disabled" v-model="form.collectionUserId" format="{name}_{deptName}_{postName}" :params="{ tenantId: '000000', deptId: form.collectionDeptId }" :fillModel.sync="form" :fills="[{ source: 'name', target: 'collection_user_name' }]">
          <template slot-scope="{ item }">
            <span style="float: left">{{ item.name }}{{ item.postName ? `(${item.postName})` : "" }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
          </template>
        </model-search-select>
      </template>
    </avue-crud>
    <el-dialog title="资产标签" :visible.sync="dialogVisible" width="600px">
      <div ref="AssetTag" class="pad-5" style="margin: 0 auto">
        <AssetTag v-if="dialogVisible" :rows="rows"></AssetTag>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { saveMetaCommon, updateMetaCommon, getMetaCommonList, delMetaCommon, getMetaCommonListTree } from "@/api/busiMode/metaCommon"
import * as deptService from "@/api/system/dept"
import { j2printer, jfAssetList, wdzAssetList } from "@/api/asset/index.js"
import { getPicListByIds } from "@/api/busiMode/metaCommon"
import * as dictService from "@/api/system/dictbiz"
import AssetTag from "./asset-tag.vue"

import { importAssetsDetail, updateImport } from "@/api/asset/index.js"

export default {
  components: { AssetTag },
  data() {
    let query = this.$route.query
    return {
      deptParams: {},
      dialogVisible: false,
      dialogVisible1: false,
      rows: [],
      deepOption: {},
      loading: {
        using_unitLoading: false,
        using_personnelLoading: false,
      },
      dictData: {
        using_unit: [],
        using_personnel: [],
      },
      assetCode: "",
      form: {
        system_asset_code: "",
        finance_asset_code: "",
        using_personnel: "",
        asset_belong_org: "",
        collection_dept_id: "",
        collectionUserId: "",
      },

      catalogModel: "wisdom_park.wp_asset_category",
      assetView: "wisdom_park.v_wp_asset_export_stat",
      tableLoading: false,
      downLoading: false,
      tableData: [],
      search: {
        asset_belong_org: "",
        collection_dept_id: "",
        collectionUserId: "",
        cw_start_date: "",
        ...query,
      },
      dialogType: "",
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableSelections: [],
      option: {
        height: "auto",
        searchMenuSpan: 4,
        searchMenuPosition: "left",
        dialogWidth: "80%",
        searchIndex: 2,
        searchIcon: true,
        border: true,
        viewBtn: true,
        align: "center",
        menuWidth: 280,
        labelWidth: 120,
        selection: true,
        reserveSelection: true,
        defaultSort: {
          prop: "cw_start_date",
          order: "ascending",
        },
        column: [
          {
            label: "所属产品",
            prop: "assetGoodId",
            showColumn: false,
            hide: true,
            rules: [{ required: true, message: "请选择所属产品", trigger: "blur" }],
          },
          {
            label: "资产类别",
            prop: "assetCategoryCode",
            type: "tree",
            addDisabled: false,
            props: {
              label: "asset_category_name",
              value: "id",
            },
            span: 12,
            dicData: [],
            dataType: "string",
            rules: [{ required: true, message: "请选择资产类别", trigger: "blur" }],
          },
          {
            label: "资产编码",
            prop: "systemAssetCode",
            rules: [{ required: true, message: "请输入资产编码", trigger: "blur" }],
            width: 150,
            showColumn: true,
            search: true,
            searchSpan: 5,
            span: 12,
            searchLike: true,
            addDisabled: false,
            searchOrder: 9,
          },
          {
            label: "资产名称",
            prop: "assetName",
            width: 200,
            search: true,
            searchSpan: 5,
            span: 12,
            searchLike: true,
            rules: [{ required: true, message: "请输入资产名称", trigger: "blur" }],
            searchOrder: 8,
          },
          {
            label: "使用日期",
            prop: "cwStartDate",
            showColumn: true,
            type: "date",
            width: 120,
            sortable: "custom",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            sortable: "custom",
          },
          {
            label: "财务编码",
            prop: "financeAssetCode",
            minWidth: 180,
            hide: true,
            showColumn: true,
            disabled: true,
            searchSpan: 8,
            span: 12,
            search: false,
          },
          {
            label: "资产型号",
            prop: "assetModel",
            span: 12,
            disabled: true,
            showColumn: false,
            width: 160,
            hide: true,
          },
          {
            label: "资产规格",
            prop: "assetNorms",
            showColumn: true,
            span: 12,
            disabled: true,
            hide: true,
          },
          {
            label: "计量单位",
            prop: "unitOfMeasure",
            showColumn: true,
            span: 12,
            disabled: true,
            hide: true,
          },
          {
            label: "资产价格(元)",
            prop: "assetVlaue",
            minWidth: 100,
            align: "right",
            showColumn: true,
            searchSpan: 8,
            span: 12,
            search: false,
          },
          {
            label: "折旧率",
            prop: "depreciationRate",
            hide: true,
            showColumn: true,
            append: "%",
            span: 12,
          },
          {
            label: "资产数量",
            prop: "assetQuantity",
            showColumn: true,
            hide: true,
          },
          {
            label: "所属机构",
            prop: "assetBelongOrg",
            showColumn: true,
            display: false,
            width: 200,
          },
          {
            label: "所属机构",
            prop: "assetBelongOrgId",
            dicData: [],
            type: "select",
            width: 200,
            showColumn: false,
            hide: true,
            props: {
              label: "title",
            },
            rules: [
              {
                required: false,
                message: "请选择机构",
                trigger: "click",
              },
            ],
          },
          {
            label: "使用部门",
            prop: "collectionDeptName",
            showColumn: true,
            display: false,
            width: 200,
            span: 12,
          },
          {
            label: "使用部门",
            prop: "collectionDeptId",
            type: "tree",
            showColumn: false,
            hide: true,
            width: 200,
            span: 12,
          },
          {
            label: "使用人员",
            prop: "collectionUserName",
            showColumn: true,
            display: false,
            width: 120,
            span: 12,
          },
          {
            label: "使用人员",
            prop: "collectionUserId",
            showColumn: false,
            hide: true,
            width: 200,
            span: 12,
          },
          {
            label: "位置",
            prop: "locationName",
            display: false,
            width: 250,
          },

          {
            label: "当前状态",
            prop: "assetCurrentStatus",
            type: "select",
            dicUrl: dictService.getUrl("AssetCurrentStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",

            width: 120,
            rules: [{ required: true, message: "请选择当前状态", trigger: "blur" }],
            searchOrder: 6,
          },
          {
            label: "财务入账日期",
            prop: "cwRzDate",
            showColumn: true,
            type: "date",
            width: 200,
            valueFormat: "yyyy-MM-dd",
            span: 12,
            hide: true,
          },
          {
            label: "详细地址",
            prop: "addressDetail",
            width: 250,
            span: 12,
          },

          {
            label: "备注",
            prop: "remarks",
            width: 200,
            showColumn: true,
            span: 24,
            type: "textarea",
            hide: true,
          },
          {
            label: "资产照片",
            prop: "assetPictures",
            hide: true,
            showColumn: false,
            type: "upload",
            listType: "picture-card",
            span: 24,
            value: [],
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
          {
            label: "标签照片",
            prop: "assetLabelPictures",
            hide: true,
            showColumn: false,
            type: "upload",
            listType: "picture-card",
            span: 24,
            value: [],
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      orders: { sort: "DESC" },
    }
  },
  props: {
    queryParams: {
      type: Object,
      default() {
        return null
      },
    },
    requireQueryParams: {
      type: Boolean,
      default: false,
    },
    calcHeight: {
      type: Number,
      default: 54,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    tableOption: {
      type: Object,
      default() {
        return null
      },
    },
    model: {
      type: String,
      default: "wp_asset_detail",
    },
  },
  watch: {
    queryParams() {
      this.page.currentPage = 1
      this.onLoad()
    },
  },
  mounted() {
    this.initData()
    this.initdeptData()
    this.deepOption = JSON.parse(JSON.stringify(this.option.column))
  },
  methods: {
    importTamplateDownload() {
      this.downFile(this.importTemplateDownUrl, this.importTeamplateDownFileName)
    },

    sortChange({ column, prop, order }) {
      this.orders = {
        sort: order === "descending" ? "DESC" : "ASC",
      }
      this.onLoad()
    },
    tableSelection(ev) {
      this.tableSelections = ev
    },
    printer() {
      if (this.tableSelections.length < 1) {
        this.$message.warning("请至少选中一条资产")
        return
      }
      this.$confirm("确定打印选中资产标签?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = []
        this.tableSelections.forEach((item) => {
          data.push({
            asset_name: item.assetName || "",
            system_asset_code: item.systemAssetCode || "",
            asset_model: item.assetModel || "",
            collection_dept_name: item.collectionDeptName || "",
            location_name: item.locationName || "",
            address_detail: item.addressDetail || "",
            cw_start_date: item.cwStartDate || "",
            asset_belong_org: item.assetBelongOrg || "",
            collection_user_name: item.collectionUserName || "",
            id: `${item.id}`,
          })
        })
        j2printer(data)
          .then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            })
          })
          .catch((e) => {
            this.$message({
              type: "error",
              message: "打印失败，未连接到打印适配程序，请联系管理员!",
            })
          })
      })
    },
    assetGoodChange(ev) {
      if (ev.value) {
        let data = ev.data.find((item) => item[ev.valueKey] === ev.value)
        if (data) {
          this.form["assetName"] = data["name"]
          ;["assetBrand", "assetCategoryCode", "assetModel", "assetNorms", "unitOfMeasure", "assetVlaue", "expectedUsefulLife", "financeAssetCode", "importNums"].forEach((key) => {
            if (typeof this.findObject(this.option.column, key) === "object") {
              this.form[key] = data[key]
            }
            if (typeof this.findObject(this.option.column, `asset_goods_${key}`) === "object") {
              this.form[`asset_goods_${key}`] = data[key]
            }
          })
        }
      }
    },
    asset_belong_org_id_change(ev) {},
    collectionDeptIdData(ev, done) {
      deptService.getDeptTree().then((res) => {
        done(res.data.data || [])
      })
    },
    assetBelongOrgData(ev, done) {
      deptService.getDeptTree().then((res) => {
        done(res.data.data || [])
      })
    },
    uploadMethod(type) {
      this.dialogType = type
      this.dialogVisible1 = "true"
    },
    initdeptData() {
      deptService.getDeptTree().then((res) => {
        const column = this.findObject(this.option.column, "assetBelongOrgId")
        column.dicData = res.data.data
      })
    },

    storage_location_codeChange(data) {
      this.form["location_name"] = data.label
    },
    using_personnelChange(ev) {
      if (ev && ev.item) {
        this.form["collection_user_name"] = ev.item.name
        this.form["collection_dept_name"] = ev.item.deptName
        this.form["collection_dept_id"] = ev.item.deptId
      } else {
        this.form["collection_dept_id"] = ""
        this.form["collection_dept_name"] = ""
      }
    },
    importFile(done) {
      if (!this.$refs.importFile.uploadFiles.length) {
        this.$message.error("请选择文件")
        done()
      } else {
        importAssetsDetail(this.$refs.importFile.uploadFiles[0].raw)
          .then((res) => {
            this.$message.success("操作成功")
            this.dialogVisible1 = false
            done()
            this.onLoad()
          })
          .catch((e) => {
            done()
          })
      }
    },
    plgxImportFile(done) {
      if (!this.$refs.importFile.uploadFiles.length) {
        this.$message.error("请选择文件")
        done()
      } else {
        updateImport(this.$refs.importFile.uploadFiles[0].raw)
          .then((res) => {
            this.$message.success("操作成功")
            this.dialogVisible1 = false
            done()
            this.onLoad()
          })
          .catch((e) => {
            done()
          })
      }
    },

    printTag() {
      let dom = this.$refs.AssetTag
      let cloneDom = dom.cloneNode(true)
      this.$Print(cloneDom)
    },
    showAssetTag(row) {
      this.rows = [
        {
          id: row.id,
          system_asset_code: row.systemAssetCode,
          asset_name: row.assetName,
          asset_model: row.assetModel,
          using_unit: row.collectionDeptName,
          cw_start_date: row.cwStartDate,
        },
      ]
      this.dialogVisible = true
    },
    getMetaCommonList(code, prop, params) {
      let obj = {}
      Object.keys(params).forEach((ele) => {
        if (!ele.includes("$")) {
          obj[ele] = params[ele]
        }
      })
      return getMetaCommonList(`wisdom_park.${code}`, {
        current: 1,
        size: 500,
        obj,
      }).then((res) => {
        if (res.data && res.data.data && prop) {
          this.dictData[prop] = res.data.data.records
        }
        return res
      })
    },
    searchModel(queryStr, modelCode, prop, searchFieldName, inputParams) {
      let params = {}
      if (queryStr) {
        params = {
          wheres: [searchFieldName ? { field: searchFieldName, value: queryStr } : { field: "name", op: "LIKE", value: queryStr }],
        }
      }
      if (inputParams) {
        params["wheres"] = params.wheres || []
        Object.keys(inputParams).forEach((key) => {
          params.wheres.push({
            field: key,
            value: inputParams[key],
          })
        })
      }
      this.loading[prop + "Loading"] = true
      this.getMetaCommonList(modelCode, prop, params).then((res) => {
        this.loading[prop + "Loading"] = false
      })
    },
    usingUnitChange() {
      this.form["using_personnel"] = ""
      this.dictData.using_personnel = []
      if (this.form["using_unit"]) {
        this.searchModel("", "wp_employee", "using_personnel", null, {
          company_id: this.form["using_unit"],
        })
      }
    },
    getPath(data, value) {
      let resultPath = []
      let getPath = (data, pathObj) => {
        pathObj = pathObj || {
          has: false,
          path: [],
        }
        data.forEach((item) => {
          if (item.id === value) {
            pathObj.path.push(item)
            pathObj.has = true
          } else {
            if (item.children) {
              pathObj.path.push(item)
              getPath(item.children, pathObj)
            }
          }
        })
        return pathObj
      }
      if (data) {
        data.forEach((item) => {
          if (item.id === value) {
            resultPath.push(item)
          } else {
            if (item.children) {
              let path = getPath(item.children, {
                has: false,
                path: [item],
              })
              if (path && path.has) {
                resultPath = path.path
              }
            }
          }
        })
      }
      return resultPath
    },
    initData(type) {
      getMetaCommonListTree(this.catalogModel, {
        labelField: "asset_category_name",
        valueField: "id",
        releaseField: "parent_id",
        isLazy: false,
      }).then((res) => {
        const column = this.findObject(this.option.column, "assetCategoryCode")
        column.dicData = res.data.data
      })
    },
    treeLoad(tree, treeNode, resolve) {
      const parentId = tree.id
      getMetaCommonListTree(this.catalogModel, {
        labelField: "asset_category_name",
        valueField: "id",
        releaseField: "parent_id",
        rootValue: parentId,
      }).then((res) => {})
    },
    searchChange(form, done) {
      this.page.currentPage = 1
      this.onLoad(done)
    },
    /**
     * 加载数据
     */
    onLoad(done) {
      let onloadFunc = () => {}
      if (this.model.includes("feng")) {
        onloadFunc = jfAssetList
      } else {
        onloadFunc = wdzAssetList
      }
      this.tableData = []
      if (this.requireQueryParams && !Object.keys(this.queryParams).length) {
        return []
      }
      this.tableLoading = true
      console.log(this.queryParams)
      onloadFunc({
        current: this.page.currentPage,
        size: this.page.pageSize,
        ...this.search,
        assetCategoryCode: this.queryParams.asset_category_code,
        ...this.orders,
        // orders: [
        //   { field: "create_time", sort: "DESC" },
        //   { field: "cw_start_date", sort: this.orders.sort },
        // ],
      })
        .then((res) => {
          console.log(res)
          this.tableLoading = false
          this.tableData = res.data.data.records || []
          this.page.total = res.data.data.total || 0
          done && done()
        })
        .catch((e) => {
          this.tableLoading = false
          done && done()
        })
    },
    saveTableForm(row, done, loading) {
      console.log(row)
      let body = {}
      body.asset_good_id = row.assetGoodId
      body.asset_category_code = row.assetCategoryCode
      body.system_asset_code = row.systemAssetCode
      body.asset_name = row.assetName
      body.cw_start_date = row.cwStartDate
      body.finance_asset_code = row.financeAssetCode
      body.asset_model = row.assetModel
      body.asset_norms = row.assetNorms
      body.unit_of_measure = row.unitOfMeasure
      body.asset_vlaue = row.assetVlaue
      body.depreciation_rate = row.depreciationRate
      body.asset_quantity = row.assetQuantity
      body.asset_belong_org = row.assetBelongOrg
      body.asset_belong_org_id = row.assetBelongOrgId
      body.collection_dept_name = row.collectionDeptName
      body.collection_dept_id = row.collectionDeptId
      body.collection_user_id = row.collectionUserId
      body.collection_user_name = row.collectionUserName || row.collection_user_name
      body.location_name = row.locationName
      body.asset_current_status = row.assetCurrentStatus
      body.cw_rz_date = row.cwRzDate
      body.address_detail = row.addressDetail
      body.remarks = row.remarks
      body.asset_pictures = row.assetPictures
      body.asset_label_pictures = row.assetLabelPictures

      if (row.id) {
        body = {
          update: body,
          primaryKeyValue: row.id,
          primaryKey: "id",
        }
      } else {
        body = {
          values: body,
        }
      }
      ;(row.id ? updateMetaCommon : saveMetaCommon)("wisdom_park." + this.model, body)
        .then((res) => {
          this.responeHandle(res, done, loading)
        })
        .catch((e) => {
          loading()
        })
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done()
        this.onLoad()
        this.$message.success(res.data.msg)
      } else {
        loading && loading()
        this.$message.error(res.data.msg)
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return delMetaCommon("wisdom_park." + this.model, { pk: "id", ids: [row.id] })
        })
        .then(() => {
          this.onLoad()
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        })
    },
    beforeClose(done) {
      this.parentId = ""
      const column = this.findObject(this.option.column, "assetCategoryCode")
      column.value = ""
      column.addDisabled = false
      column.disabled = false
      this.form = {
        systemAssetCode: "",
        financeAssetCode: "",
        using_personnel: "",
      }
      done()
    },
    getPicListByIds(ids) {
      return new Promise((resolve, reject) => {
        let attachment_ids = ids ? ids.split(",") : []
        let fileList = []
        if (attachment_ids && attachment_ids.length) {
          getPicListByIds(attachment_ids).then((res) => {
            if (res && res.data && res.data.data) {
              res.data.data.forEach((item) => {
                let fileItem = {
                  label: item.id,
                  value: item.link,
                }
                fileList.push(fileItem)
              })
            }
            resolve(fileList)
          })
        } else {
          resolve(fileList)
        }
      })
    },
    beforeOpen(done, type) {
      this.option.column = JSON.parse(JSON.stringify(this.deepOption))
      if (type === "add") {
        this.option.column.forEach((item) => {
          item.disabled = item.addDisabled !== undefined ? item.addDisabled : !!item.disabled
        })
      }
      if (type === "add" && this.queryParams["assetCategoryCode"]) {
        const column = this.findObject(this.option.column, "assetCategoryCode")

        column.value = this.queryParams["assetCategoryCode"]
        this.form["assetCategoryCode"] = this.queryParams["assetCategoryCode"]
        column.disabled = true
      }
      if (["add", "edit", "view"].includes(type)) {
        this.initData()
      }
      // if (["edit", "view"].includes(type)) {
      //   ;["assetPictures", "assetLabelPictures"].forEach((name) => {
      //     if (this.form[name]) {
      //       this.getPicListByIds(this.form[name]).then((fileList) => {
      //         this.form[name] = fileList
      //       })
      //     } else {
      //       this.form[name] = []
      //     }
      //   })
      // }
      done()
    },
  },
}
</script>
